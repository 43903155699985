<template>
  <div>
    <div class="topLines">
      <span
        :class="
          questionDetail_data.type == 1
            ? 't1'
            : questionDetail_data.type == 2
            ? 't2'
            : 't3'
        "
        >{{
          questionDetail_data.type == 1
            ? "简单"
            : questionDetail_data.type == 2
            ? "复杂"
            : "困难"
        }}</span
      >
      <span style="margin-left: 20px"
        ><i class="el-icon-success"></i> 完成人数：{{
          question_detail_answer_data_count
        }}</span
      >
    </div>
    <p class="ms">描述</p>
    <p class="msxx">
      {{ questionDetail_data.describe_data }}
    </p>
    <p class="srms">输入描述：</p>
    <p class="msxx">{{ questionDetail_data.input_describe }}</p>
    <p class="srms">输出描述：</p>
    <p class="msxx">
      {{ questionDetail_data.output_describe }}
    </p>
    <p class="ms">示例1</p>
    <div class="shili">
      <pre>{{ questionDetail_data.example_data || "暂无" }}</pre>
    </div>
  </div>
</template>
<script>
import * as http from "@/api/order.js";
export default {
  data() {
    return {
      queryValue: {},
      questionDetail_data: {},
      question_detail_answer_data: {},
      question_detail_answer_data_count: "",
    };
  },
  mounted() {
    this.$emit("getQuery", this.$route.query);
    this.$emit("loadingType", true);
    this.getList();
    // http.grade_question_detail(this.$route.query).then((res) => {
    //   this.$emit("getTitle", res.questionDetail_data.title);
    //   this.$emit("getTm_px", res.tm_px);
    //   this.questionDetail_data = res.questionDetail_data;
    //   this.tm_px = res.tm_px;
    //   this.question_detail_answer_data_count =
    //     res.question_detail_answer_data_count;
    //   if (
    //     res.question_detail_answer_data.file_data &&
    //     res.question_detail_answer_data.file_data.length
    //   ) {
    //     this.$emit("getStuJob", res.question_detail_answer_data);
    //     this.question_detail_answer_data = res.question_detail_answer_data;
    //   } else {
    //     this.$emit("getStuJobNull");
    //     console.log("没有数据");
    //   }
    //   this.$emit("loadingType", false);
    // });
    // console.log("A");
  },
  methods: {
    getList(id) {
      if (id) {
        this.$route.query.question_id = id;
      }
      this.$emit("loadingType", true);
      http.grade_question_detail(this.$route.query).then((res) => {
        this.$emit("getTitle", res.questionDetail_data.title);
        this.$emit("getTm_px", res.tm_px);
        this.questionDetail_data = res.questionDetail_data;
        this.tm_px = res.tm_px;
        this.question_detail_answer_data_count =
          res.question_detail_answer_data_count;
        if (
          res.question_detail_answer_data.file_data &&
          res.question_detail_answer_data.file_data.length
        ) {
          this.$emit("getStuJob", res.question_detail_answer_data);
          this.question_detail_answer_data = res.question_detail_answer_data;
        } else {
          this.$emit("getStuJobNull");
          console.log("没有数据");
        }
        this.$emit("loadingType", false);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.topLines {
  padding: 10px 16px;
  color: rgb(102, 102, 102);
  font-size: 12px;
}
.ms {
  font-size: 16px;
  color: #333;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 16px;
}
.msxx {
  color: #666;
  font-size: 14px;
}
.srms {
  color: #333;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 16px;
  font-size: 14px;
}
.shili {
  background-color: #f7f8f9;
  border-radius: 4px;
  padding: 12px;
  box-sizing: border-box;
  color: #666;
  font-size: 14px;
  pre {
    margin: 6px 0;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
}
.t1 {
  color: #3b97ff;
}
.t2 {
  color: #32ca99;
}
.t3 {
  color: #ffaa20;
}
</style>
